<template>
  <v-app>
    <nav-component :drawer="false" title="Login"/>
    <v-main>
      <div style="alignment: center;">
        <v-card style="max-width: 600px; margin: 150px auto auto auto; padding: 50px;">
          <h1>Login</h1>
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Email:</span>
            <v-text-field type="email" v-model="user" @input="() => {user = user.toLowerCase()}"/>
          </div>
          <div>
            <loading-overlay :show="loading"></loading-overlay>
            <p v-if="loading"></p>
            <div class="framed-text" v-if="loading">Please Approve the login request on your phone</div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: center;" v-if="usePassword">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Password:</span>
            <v-text-field type="password" v-model="password" :rules="[validationRules.password]" />
            <v-btn color="gey" @click="changeUsePassword">Change</v-btn>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: center;"  v-if="!usePassword">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Msisdn:</span>
            <v-text-field v-model="msisdn" autocomplete="off"/>
            <v-btn color="gey" @click="changeUsePassword">Change</v-btn>
          </div>
          <v-btn color="secondary" @click="next">Next</v-btn>
          <v-btn class="float-right" color= "orange" @click="unibeamLogin">SIA</v-btn>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<style scoped>
.framed-text {
  border: 2px solid black;
  font-weight: bold;
  padding: 20px;
  font-size: 22px;
  text-align: center;
  color: red;
  width: 80%;
  margin: 0 auto;
}
</style>
<script>

import jsSHA from 'jssha'
import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";
import authService from '@/authService';
import LoadingOverlay from '@/components/LoadingOverlay.vue'; // Import your LoadingOverlay component

export default {
  mixins: [utils],
  data() {
    return {
      user: null,
      password: null,
      autofilled: false,
      loading: false,
      usePassword: true,
      msisdn: null,
      validationRules: {
        // eslint-disable-next-line no-useless-escape
        email: v => (!v || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v)) || 'Please enter a valid email',
        password: v => (!v || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!()])(?=\S+$).{8,}$/.test(v)) || 'password must be minimum 8 characters, must contain at least one number, at least one capital letter, at least one small letter and at least one special character'
      }
    }
  },
  watch: {
    'user'() {
      this.autofilled = true;
    },
    'password'() {
      this.autofilled = true;
    },
    'msisdn'() {
      this.autofilled = false;
    }
  },
  components: {
    NavComponent,
    LoadingOverlay
  },
  methods: {
    async changeUsePassword() {
      this.usePassword = !this.usePassword;
      // Reset msisdn field when switching from password to msisdn
      if (!this.usePassword) {
        this.resetMsisdn();
      }
    },
    resetMsisdn() {
      console.log('resetting msisdn');
      this.msisdn = ''; // Reset msisdn to empty string
    },
    async next() {
      if (!this.password || this.password.length === 0) {
        alert('You have to enter the password')
        return;
      }

      const shaObj = new jsSHA("SHA-512", "TEXT", {encoding: "UTF8"});
      shaObj.update(this.password);

      const result1 = await this.httpGet('api/auth/login1', {'Authorization': 'Basic ' + btoa(this.user + ':' + shaObj.getHash('HEX'))});
      if (!result1) {
        const result2 = await this.httpGet('api/auth/login/check', {'username': this.user});
        if (result2 && result2.data.locked) {
          alert('User locked. Contact your admin');
        } else {
          alert('Wrong credentials');
        }
        return;
      }

      authService.setMFA();
      await this.$router.replace({path: result1.data});
    },
    async unibeamLogin() {
      if (this.usePassword) {
        if (!this.password || this.password.length === 0) {
          alert('You have to enter the password')
          return;
        }

        const shaObj = new jsSHA("SHA-512", "TEXT", {encoding: "UTF8"});
        shaObj.update(this.password);

        const result1 = await this.httpGet('api/auth/unibeamLogin', {'Authorization': 'Basic ' + btoa(this.user + ':' + shaObj.getHash('HEX'))});
        if (!result1) {
          alert('unibeamLogin responded with error');
          return;
        }
        authService.setMFA();
        this.loading = true;
        const result = await this.httpGet('api/auth/unibeamLogin2');
        if (result) {
          authService.login();
          localStorage.user = JSON.stringify(result.data);
          this.loading = false;
          this.$router.replace({path: '/'});
        } else
          this.loading = false;
      } else {
        if (!this.msisdn || this.msisdn.length === 0) {
          alert('You have to enter the msisdn')
          return;
        }

        try {
          const result = await this.httpPost('api/auth/loginWithMsisdn', {"email": this.user, "msisdn": this.msisdn});
          if (result) {
            authService.setMFA();
            this.loading = true;
            const result1 = await this.httpGet('api/auth/unibeamLogin', {'Authorization': 'Basic ' + btoa(this.user + ':' + result.data)});
            if (!result1) {
              alert('unibeamLogin responded with error');
              return;
            }
            authService.setMFA();
            this.loading = true;
            const result2 = await this.httpGet('api/auth/unibeamLogin2');
            if (result2) {
              authService.login();
              localStorage.user = JSON.stringify(result2.data);
              this.loading = false;
              this.$router.replace({path: '/'});
            } else
              this.loading = false;
          } else
            this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }
}
</script>
